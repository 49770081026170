import {
    Box,
    Text
} from '@chakra-ui/react';

export default function Footer() {
    return (
        <Box
            borderTop={'1px solid pink'}
            p={'0.2em'}
            fontSize={'xl'}
            id="footer"
        >
            <Text align={'center'}>Built with 💓 by @royastik27</Text>
        </Box>
    );
}