import './App.css';

import Navbar from './components/primary/Navbar/Navbar.js';
import MainContent from './components/primary/MainContent/MainContent.js';
import Footer from './components/primary/Footer/Footer.js';

function App() {
  return (
    <div className="App">

      <Navbar />
      <MainContent />
      <Footer />

    </div>
  );
}

export default App;
