import { Link } from 'react-router-dom';

import {
    Box,
    Stack,
    Flex,
    Button,
    Icon,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
} from '@chakra-ui/react';

import { FaUser } from "react-icons/fa";
import { ChevronRightIcon } from '@chakra-ui/icons';

function UserMenuItem({ menu }) {
    return (
        <Box
            as={Link}
            to={menu.href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ backgroundColor: 'pink.50' }}
            fontSize={'lg'}
        >
            <Stack direction={'row'} align={'center'}>

                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{
                          textDecoration: 'underline',
                        }}
                    >
                        {menu.label}
                    </Text>
                </Box>

                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                >
                    <Icon color={'gray.800'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Box>
    );
}

export default function UserPopover() {
    return (
        <Popover>

            <PopoverTrigger>
                <Button variant="outline">
                    <Icon as={FaUser} />
                </Button>
            </PopoverTrigger>

            <PopoverContent
                border={0}
                boxShadow={'xl'}
                rounded={'xl'}
            >

                <PopoverArrow />
                <PopoverHeader>
                    <center>
                        <Text w="70%">
                            Use your account to enjoy more! 😉
                        </Text>
                    </center>
                </PopoverHeader>

                <PopoverBody>

                    {
                        MENUS.map((menu, idx) =>
                            <UserMenuItem key={idx} menu={menu} />
                        )
                    }

                </PopoverBody>

            </PopoverContent>

        </Popover>
    );
}

const MENUS = [
    {
        label: 'Log in',
        href: '/login'
    },
    {
        label: 'Create an account',
        href: '/signup'
    },
    {
        label: "Don't give a shit",
        href: '/dontgiveashit'
    }
];