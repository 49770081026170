import { useState } from 'react';

import {
    Box,
    Flex,
    Text
} from '@chakra-ui/react';

export default function RockyAstik() {

    const [ coins, setCoins ] = useState(0);

    const handleClick = () => {
        setCoins(coins+1);
    }

    return (
        <Box
            m={'0.5em'}
            p={'1em'}
            border={'1px solid pink'}
            className='no-select'
        >
            <Text mb={'1em'} fontSize={'xl'} fontWeight={'bold'} align="center">RockyAstik</Text>

            <Flex justify={'center'}>
                <Text fontFamily={'arvo'} color={'green'} fontWeight={'bold'} fontSize={'4xl'}>${coins.toLocaleString()}</Text>
            </Flex>

            <Flex
                direction='column'
                justify='center'
                m={'1em auto'}
                w={'20em'}
                h={'20em'}
                backgroundColor={'pink'}
                borderRadius={'50%'}
                cursor={'pointer'}
                boxShadow={'2xl'}
                _hover={{ transform: 'scale(1.05)' }}
                onClick={handleClick}
            >
                <Text fontSize='4xl' m={'0 auto'}>SMASH ME!</Text>
            </Flex>
        </Box>
    );
}