import { Routes, Route } from 'react-router-dom';

import {
    Box,
    Text
} from '@chakra-ui/react';

import RockyAstik from '../../secondary/RockyAstik/RockyAstik';

export default function MainContent() {
    return (
        <Box
            minH={'88vh'}
            id="main-content"
        >
            {/* <Text fontSize="9xl" align="center">Um too Sexy for you, BOMBO!</Text> */}
            <RockyAstik />
        </Box>
    );
}